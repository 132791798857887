<script>
/**
 * Rimosso DatePicker (Vedi pratica/advanced_search)
 * 
 *      :shortcuts      = "shortcuts"
 */
import { mapState, mapGetters } from 'vuex';

import elements from '@/vue-model-mirror/bnvform/components';
import advanced_search  from './advanced_search';
import date_fields from '../praticav2/components/date_fields';
import DatePicker from 'vue2-datepicker';

const moment = require('moment');

export default {
    name: 'appuntamento-filters',
    props: [ 'regione_id', 'provincia_id', 'comune_id', 'show_mine', 'no_luogo_appuntamento', 'primo_contatto_incompleto', 'appuntamento_done', 'ispezione_done', 'show_map', 'mostra_assegnati' ],
    components : { 'advanced-search' : advanced_search,  DatePicker},
    computed : {
        ...mapState('comune_provincia', {
            reload_prov : state => state.reload_select,
        }),
        ...mapState('comune_select', {
            reload_comune_id : state => state.reload_select,
        }),
        ...mapGetters( 'comune_regione', { get_label_regione: 'get_label' } ),
        ...mapGetters( 'comune_provincia', { is_present_prov: 'is_present', get_label_provincia: 'get_label' } ),
        ...mapGetters( 'comune_select', { is_present_comune: 'is_present', get_label_comune: 'get_label' } ),
        local_comune_id_disabled() {
            if ( !this.local_reg.value && !this.local_prov.value ) { return true; }
            return false;
        },
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        _date_filters: {
            get() { return this.$store.state.appuntamento.filters.date_filters; },
            set( value ) { this.$store.dispatch( 'appuntamento/set_date_filters', value ) },
        },

    },
    watch: {
        reload_prov( new_value ) {
            if ( !new_value ) { return; }
            if ( !this.is_present_prov( this.local_prov.value ) ) { this.local_prov.value = ''; }
            this.local_prov.key += 1;
        },
        reload_comune_id( new_value ) {
            if ( !new_value ) { return; }
            if ( !this.is_present_comune( this.local_comune_id.value ) ) { this.local_comune_id.value = ''; }
            this.local_comune_id.key += 1;
        },
    },
    methods : {
        update_show_mine( value ) {
            this.$emit('change', { show_mine: value });
        },
        update_show_map( value ) {
            this.$emit('change', { show_map: value });
        },
        update_no_luogo_appuntamento( value ) {
            this.$emit('change', { no_luogo_appuntamento: value });
        },
        update_primo_contatto_incompleto( value ) {
            this.$emit('change', { primo_contatto_incompleto: value });
        },
        update_mostra_assegnati( value ) {
            this.$emit('change', { mostra_assegnati: value });
        },
        update_appuntamento_done( value ) {
            this.$emit('change', { appuntamento_done: value });
        },
        update_ispezione_done( value ) {
            this.$emit('change', { ispezione_done: value });
        },
        remove_date_filter( index ) {
            this.date_filters.splice( index, 1 );
            this._date_filters = this.date_filters;
        },
        set_date_field( index ) {
            this.add_date_row( index );
        },
        set_range( index ) {
            this.add_date_row( index );
        },
        add_date_row( index ) {
            if ( this.date_filters[ index ].field && this.date_filters[ index ].range ) {
                this.date_filters.push( { field: '', range: null } );
                this._date_filters = this.date_filters;
            }
        },
        update_filters( payload ) {
            let res = {}; let label;
            if ( payload.hasOwnProperty('regione_id') )   {
                res.regione = {};
                if ( payload.regione_id )   {
                    this.local_reg.value = payload.regione_id;
                    label = this.get_label_regione( payload.regione_id );
                    res.regione.id = payload.regione_id;
                    res.regione.label = label;
                } else {
                    this.local_reg.value = '';
                    res.regione.id = '';
                    res.regione.label = null;
                }
            }
            if ( payload.hasOwnProperty('provincia_id') ) {
                res.provincia = {};
                if ( payload.provincia_id ) {
                    this.local_prov.value = payload.provincia_id;
                    label = this.get_label_provincia( payload.provincia_id );
                    res.provincia.id = payload.provincia_id;
                    res.provincia.label = label;
                } else {
                    this.local_prov.value = '';
                    res.provincia.id = '';
                    res.provincia.label = null;
                }
            }
            if ( payload.hasOwnProperty('comune_id') ) {
                res.comune = {};
                if ( payload.comune_id ) {
                    this.local_comune_id.value = payload.comune_id;
                    label = this.get_label_comune( payload.comune_id );
                    res.comune.id = payload.comune_id;
                    res.comune.label = label;
                } else {
                    this.local_comune_id.value = '';
                    res.comune.id = '';
                    res.comune.label = null;
                }
            }
            this.$emit('change', res);
        },
        set_filter( payload ) {
            this.$emit('set_filters', payload);
        },
        set_filters() {
            // date_filters
            let date_filters = [];
            for ( var i = 0; i < this.date_filters.length; i++ ) {
                if ( this.date_filters[ i ].range === null ) { break; }
                date_filters.push( this.date_filters[ i ] );
            }
            this.$emit('change', {date_filters : date_filters});
          //  this.$emit( 'search', { text, date_filters: date_filters.length > 0 ? date_filters : null } ); // setto il filtro
          //  this.$refs.filters.hide();
        },
    },
    created() {
        this.local_reg.value       = this.regione_id;
        this.local_prov.value      = this.provincia_id;
        this.local_comune_id.value = this.comune_id;
        this.local_show_mine       = this.show_mine;
        this.local_show_map        = this.show_map;
        this.local_no_luogo_appuntamento = this.no_luogo_appuntamento;
        this.local_primo_contatto_incompleto = this.primo_contatto_incompleto;
        // Filtro per mostrare assegnati ad itinerario
        this.local_mostra_assegnati = this.mostra_assegnati;
        // Filtri su stato appuntamento e ispezione
        this.local_appuntamento_done = this.appuntamento_done ;
        this.local_ispezione_done = this.ispezione_done;
        // Filtri sulle date
        this.date_filters = this._date_filters;
    },
    data() {
        return {
            bnvselect: elements.bnvselect,
            date_fields,
            date_filters       : [
                { field: '', range: null },
            ],
            date_value : null,
            formatter: {
                // Date to String
                stringify: (date) => {
                    return date ? moment(date).format('DD/MM/YYYY') : null
                },
                // String to Date
                parse: (value) => {
                    return value ? moment(value, 'DD/MM/YYYY').toDate() : null
                }
            },
            date_filter_panel : false,
            status_list : [
                {'value' : false, 'text' : 'Da fare'},
                {'value' : true, 'text' : 'Fatto'}
            ],
            local_reg: {
                key      : 0,
                value    : '',
                disabled : false,
            },
            local_prov: {
                key      : 500,
                value    : '',
                disabled : false,
            },
            local_comune_id: {
                key      : 1000,
                value    : '',
            },
        }
    },
}

</script>

<template>
    <div style="padding: 0 0px;">
        <!-- <b-row> -->
            <b-col style="margin-bottom: 10px">
                <b-form-checkbox
                    inline 
                    switch
                    :disabled = "![ 1, 6, 7 ].includes( user.role_id )"
                    @change   = "update_show_map"
                    id        = "map"
                    v-model   = "local_show_map"
                    >
                    Modalità mappa
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    :disabled = "![ 1, 6, 7 ].includes( user.role_id )"
                    @change   = "update_show_mine"
                    id        = "mine"
                    v-model   = "local_show_mine"
                    >
                    Le mie pratiche
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    @change   = "update_no_luogo_appuntamento"
                    id        = "no_luogo_appuntamento"
                    v-model   = "local_no_luogo_appuntamento"
                    >
                    Luogo appuntamento mancante
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    @change   = "update_primo_contatto_incompleto"
                    id        = "primo_contatto_incompleto"
                    v-model   = "local_primo_contatto_incompleto"
                    >
                    Escludi primo contatto incompleto
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    @change   = "update_mostra_assegnati"
                    id        = "mostra_assegnati"
                    v-model   = "local_mostra_assegnati"
                    >
                    Mostra assegnati
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    @change   = "update_appuntamento_done"
                    id        = "appuntamento_done"
                    v-model   = "local_appuntamento_done"
                    >
                    Appuntamento effettuato
                </b-form-checkbox><br/>
                <b-form-checkbox
                    inline 
                    switch
                    @change   = "update_ispezione_done"
                    id        = "ispezione_done"
                    v-model   = "local_ispezione_done"
                    >
                    Ispezione effettuata
                </b-form-checkbox><br/>
        </b-col>
        <b-col>
            <b-button aria-controls="date-fllters" block variant="outline-dark" @click="date_filter_panel = !date_filter_panel"><div style="display: flex;justify-content: space-between;"><span>Filtro per data</span><b-icon-plus-square variant="secondary" scale="1.4" font-scale = "1" style="padding-top: 3px" v-show="!date_filter_panel"></b-icon-plus-square><b-icon-x-square variant="secondary" scale="1.4" font-scale = "1" style="padding-top: 3px" v-show="date_filter_panel"></b-icon-x-square></div></b-button>
            <b-collapse id="date-fllters" class="mt-2" v-model="date_filter_panel">
                <b-card>
                    <div v-for="( item, index ) in date_filters">
                        <b-row :key="'date_filter_' + String( index )" style="padding-top: 10px">
                            <b-col>
                                <div v-if        = "index < date_filters.length - 1" class="drop-filtro">
                                    <b-icon-x-circle-fill
                                        style       = "margin-top: 3px; cursor: pointer;"
                                        variant     = "danger"
                                        scale       = "1.2"
                                        font-scale  = "1"
                                        @click.stop = "remove_date_filter( index )"
                                    />
                                </div>
                                <b-card style="margin: 0 -10px;">
                                    <b-select
                                        size     = "sm"
                                        :options = "date_fields"
                                        v-model  = "date_filters[ index ].field"
                                        @change  = "set_date_field( index )"
                                    />
                                    <DatePicker
                                        range
                                        lang            = "it"
                                        placeholder     = "imposta un intervallo"
                                        input-class     = "form-control form-control-sm"
                                        :append-to-body = "false"
                                        
                                        :clearable      = "false"
                                        :formatter      = "formatter"
                                        v-model         = "date_filters[ index ].range"
                                        @change         = "set_range( index )"
                                        >
                                        <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                    </DatePicker>
                                    </b-card>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row style="padding: 0 15px; margin-top: 15px" align-h="end" @click="set_filters">
                        <b-button variant="success" size="sm">Imposta</b-button>
                    </b-row>
                </b-card>
              </b-collapse>
        </b-col>
            <b-col style="margin-bottom: 10px">
                <advanced-search @search="set_filter"/>
            </b-col>
        <!-- </b-row> -->
        <!-- <b-row> -->
        <!-- SOSPESI PER  IL MOMENTO
            <b-col>
                <component
                    v-bind:key     = "local_reg.key"
                    name           = "regione_id"
                    ref            = "regione"
                    :is            = "bnvselect"
                    elementtype    = "select"
                    :value         = "local_reg.value"
                    label          = "Regione"
                    store          = "comune_regione"
                    :allownull     = "true"
                    :edit          = "true"
                    :disabled      = "local_reg.disabled"
                    :display_label = "true"
                    size           = "sm"
                    @change        = "update_filters"
                    >
                </component>
            </b-col>
            <b-col>
                <component
                    v-bind:key     = "local_prov.key"
                    name           = "provincia_id"
                    ref            = "provincia"
                    :is            = "bnvselect"
                    elementtype    = "select"
                    :value         = "local_prov.value"
                    label          = "Provincia"
                    store          = "comune_provincia"
                    :allownull     = "true"
                    :edit          = "true"
                    :disabled      = "local_prov.disabled"
                    :display_label = "true"
                    size           = "sm"
                    @change        = "update_filters"
                    >
                </component>
            </b-col>
            <b-col>
                <component
                    v-bind:key     = "local_comune_id.key"
                    name           = "comune_id"
                    ref            = "comune_id"
                    :is            = "bnvselect"
                    elementtype    = "select"
                    :value         = "local_comune_id.value"
                    label          = "Comune"
                    store          = "comune_select"
                    :allownull     = "true"
                    :edit          = "true"
                    :disabled      = "local_comune_id_disabled"
                    :display_label = "true"
                    size           = "sm"
                    @change        = "update_filters"
                    >
                </component>
            </b-col>
        -->
        <!-- </b-row> -->
    </div>
</template>

<style>
.col .mx-datepicker-range {
    width: 100%;
}
.drop-filtro {
    z-index: 1;
    position: absolute;
    right: 7px;
    top: -8px;
    margin: 0 -10px;
}
</style>
