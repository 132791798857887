<script>

const moment = require('moment');

import { mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
//import date_fields from './date_fields';

export default {
    name: 'advanced-search',
    props: [ '' ],
    components : { DatePicker },
    computed : {
        text: {
            get() { return this.$store.state.appuntamento.text_filter; },
            set( value ) { this.$store.dispatch( 'appuntamento/set_text_filter', value ) },
        },
        _date_filters: {
            get() { return this.$store.state.appuntamento.date_filters; },
            set( value ) { this.$store.dispatch( 'appuntamento/set_date_filters', value ) },
        },
        text_valid() {
            return this.$store.state.appuntamento.text_filter_valid;
        },
//      used_filter() {
//          return this.$store.getters.contains;
//      },
        shortcuts() {
            var shortc = [];
            // ultimi 14gg
            shortc.push({
                text: 'ultimi 14gg',
                onClick: () => {
                    var dateobj = moment();
                    dateobj.subtract( 14, 'days' );
                    var dates = [ dateobj.toDate(), new Date() ];
                    this.select_data( dates );
                }
            });
            // mese in corso ( default )
            shortc.push({
                text: 'mese',
                onClick: () => {
                    var dateobj = moment();
                    var from = moment( `${ dateobj.format('YYYY') }-${ dateobj.format('MM') }-01`, 'YYYY-MM-DD' );
                    var dates = [ from.toDate(), new Date() ];
                    this.select_data( dates );
                }
            });
            // mese scorso
            shortc.push({
                text: 'mese scorso',
                onClick: () => {
                    var from = moment().subtract(1, 'months').startOf('month');
                    var to = from.clone();
                    to = to.endOf('month')
                    var dates = [ from.toDate(), to.toDate() ];
                    this.select_data( dates );
                }
            });
            // anno in corso
            shortc.push({
                text: 'anno',
                onClick: () => {
                    var dateobj = moment();
                    var from = moment( `${ dateobj.format('YYYY') }-01-01`, 'YYYY-MM-DD' );
                    var dates = [ from.toDate(), new Date() ];
                    this.select_data( dates );
                }
            });
            // anno scorso
            shortc.push({
                text: 'anno scorso',
                onClick: () => {
                    var from = moment().subtract(1, 'year').startOf('year');
                    var to = from.clone();
                    to = to.endOf('year')
                    var dates = [ from.toDate(), to.toDate() ];
                    this.select_data( dates );
                }
            });
            //return shortc;
            return [];
        }
    },
    watch: {
        text( new_value ) {
//          if ( this.used_filter === new_value ) { return; }
            if ( new_value.length === 0 ) { this.clear(); return; }
            if ( new_value.length < this.min_matching_chars ) { return; }
            if ( this.timeout ) {
                clearTimeout( this.timeout );
            }
            this.timeout = setTimeout( () => {
                this.$emit( 'search', { text: new_value } ); // setto il filtro
            }, 500);
        }
    },
    methods : {
        clear() {
            this.$emit( 'search', { text: '', date_filters: null } ); // setto il filtro
        },
        reset() { this.text = ''; },
        set_filters() {
            // text
            let text = '';
            if ( this.text.length > this.min_matching_chars - 1 ) {
                text = this.text;
            }
            // date_filters
            let date_filters = [];
            for ( var i = 0; i < this.date_filters.length; i++ ) {
                if ( this.date_filters[ i ].range === null ) { break; }
                date_filters.push( this.date_filters[ i ] );
            }
            this.$emit( 'search', { text, date_filters: date_filters.length > 0 ? date_filters : null } ); // setto il filtro
            this.$refs.filters.hide();
        },
        remove_date_filter( index ) {
            this.date_filters.splice( index, 1 );
            this._date_filters = this.date_filters;
        },
        set_date_field( index ) {
            this.add_date_row( index );
        },
        set_range( index ) {
            this.add_date_row( index );
        },
        add_date_row( index ) {
            if ( this.date_filters[ index ].field && this.date_filters[ index ].range ) {
                this.date_filters.push( { field: '', range: null } );
                this._date_filters = this.date_filters;
            }
        },
        select_data( dates = null ) { // TODO
            if ( dates ) { this.dates = dates; }
            if ( !this.company_id ) { return; }
            var from = moment( this.dates[0] ).format('YYYY-MM-DD');
            var to   = moment( this.dates[1] ).format('YYYY-MM-DD');
            this.get_all( { params: { company_id: this.company_id, date: [ from, to ].join('|'), country: this.country }, force_reload: true } );
        },
    },
    created() {
        this.date_filters = this._date_filters;
    },
    data() {
        return {
            //date_fields,
            timeout            : null,
            min_matching_chars : 3,
            date_filters       : [
                { field: '', range: null },
            ],
            date_value : null,
            formatter: {
                // Date to String
                stringify: (date) => {
                    return date ? moment(date).format('DD/MM/YYYY') : null
                },
                // String to Date
                parse: (value) => {
                    return value ? moment(value, 'DD/MM/YYYY').toDate() : null
                }
            }
        }
    },
}

</script>

<template>
    <div>
        <b-input-group class="mt-3">
            <template #prepend>
                <b-button
                    style  = "background-color: white;"
                    :style = "{ 'border-color': text_valid === false ? '#dc3545' : '#ced4da' }"
                    size   = "sm"
                    @click = "reset()"
                    :class = "{ 'is-invalid': !text_valid }"
                    >
                    <b-icon-x-circle-fill variant="secondary" scale="1.4" font-scale = "1" style="padding-top: 3px" ></b-icon-x-circle-fill>
                </b-button>
            </template>
            <!-- 
            <template #append>
                <b-dropdown
                    right
                    block
                    size  = "sm"
                    style = "background-color: #F5F5F5;"
                    ref   = "filters"
                    >
                    <div style="width: 600px; padding: 0 10px 10px 10px; background-color: #F5F5F5; margin: 0">
                        <div v-for="( item, index ) in date_filters">
                            <b-row :key="'date_filter_' + String( index )" style="padding-top: 10px">
                                <b-col>
                                    <b-select
                                        size     = "sm"
                                        :options = "date_fields"
                                        v-model  = "date_filters[ index ].field"
                                        @change  = "set_date_field( index )"
                                    />
                                </b-col>
                                <b-col>
                                    <DatePicker
                                        range
                                        lang            = "it"
                                        placeholder     = "imposta un intervallo"
                                        input-class     = "form-control form-control-sm"
                                        :append-to-body = "false"
                                        :shortcuts      = "shortcuts"
                                        :clearable      = "false"
                                        :formatter      = "formatter"
                                        v-model         = "date_filters[ index ].range"
                                        @change         = "set_range( index )"
                                        >
                                        <i class="glyphicon glyphicon-th" slot="calendar-icon" />
                                    </DatePicker>
                                </b-col>
                                <b-col style="padding-top: 5px" cols="1">
                                    <b-icon-x-circle-fill
                                        v-if        = "index < date_filters.length - 1"
                                        style       = "margin-top: 3px; cursor: pointer;"
                                        variant     = "danger"
                                        scale       = "1.2"
                                        font-scale  = "1"
                                        @click.stop = "remove_date_filter( index )"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                        <b-row style="padding: 0 15px; margin-top: 15px" align-h="end" @click="set_filters">
                            <b-button variant="success" size="sm">Ok</b-button>
                        </b-row>
                    </div>
                </b-dropdown>
            </template>
            -->
            <b-input
                size        = "sm"
                v-model     = "text"
                placeholder = "Cerca ovunque"
                :class      = "{ 'is-invalid': !text_valid }"
                >
            </b-input>
        </b-input-group>
    </div>
</template>

<style>
    .dropdown-menu-right {
        background-color: #F5F5F5 !important;
    }
</style>

