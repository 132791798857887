<script>

import VueContext from 'vue-context';
import { mapActions, mapState, mapGetters } from 'vuex';

const config  = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

const { listpage }   = require('@/vue-model-mirror');
const options        = require('@/listconfig');
const meta_converter = require('@/vue-model-mirror/helpers/meta_converter').default;
const schemas        = require('@/schemaconfig');

import bnvcards  from '@/vue-model-mirror/bnvcards';
import user_list from './user_list';
import filters   from './filters';
import diary     from '../praticav2/tabs/diary';
import files     from '../praticav2/tabs/files';

import task_panel from './task_panel';

export default {
    name: 'appuntamento',
    extends: listpage,
    components : {
        'user-list'            : user_list,
        'appuntamento-filters' : filters,
        
        bnvcards,
        VueContext,
    },
    computed : {
        ...mapState('ricerca', {
            ricerca_created       : state => state.created,
            ricerca_obj           : state => state.obj,
            ricerca_newlist_error : state => state.newlist_error,
        }),
        ...mapState('appuntamento', {
            //pratiche_filters    : state => state.filters,
            pratiche_parameters : state => state.parameters,
            regione_id          : state => state.regione_id,
            provincia_id        : state => state.provincia_id,
            comune_id           : state => state.comune_id,
            //items               : state => state.items,
            //tot                 : state => state.tot,
            //loading             : state => state.loading,
        }),
        items() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.items;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.items;
            }
        },
        loading() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.loading;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.loading;
            }
        },
        tot() {
            console.log('list_mode => ' +  this.list_mode);

            if ( this.list_mode === 'default' ) {
                console.log(this.$store.state.appuntamento)
                return this.$store.state.appuntamento.tot;
            } else if ( this.list_mode === 'personal' ) {
                console.log(this.$store.state.appuntamento)
                return this.$store.state.appuntamento_list.tot;
            }
        },
        show_map() {
            return this.pratiche_filters.show_map;
        },
        ...mapState('appuntamento_list', {
            is_draggable   : state => state.is_draggable,
            report_message : state => state.message,
            report_variant : state => state.variant,
            report_loader  : state => state.report_loader,
        }),
        pratiche_filters() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.filters;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.filters;
            }
        },
        regione_id() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.regione_id;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.regione_id;
            }
        },
        provincia_id() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.provincia_id;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.provincia_id;
            }
        },
        comune_id() {
            if ( this.list_mode === 'default' ) {
                return this.$store.state.appuntamento.comune_id;
            } else if ( this.list_mode === 'personal' ) {
                return this.$store.state.appuntamento_list.comune_id;
            }
        },
        ...mapGetters( 'comune_provincia', { is_present_prov: 'is_present', prov_check_regione: 'check_regione' } ),
        ...mapGetters( 'comune_select', { is_present_comune: 'is_present', comune_check_regione: 'check_regione', comune_check_provincia: 'check_provincia' } ),
        ricerca_id: {
            get() { return this.$store.state.appuntamento.ricerca_id; },
            set( value ) { this.set_ricerca_id( value ); }
        },
        edit_mode: {
            get() { return this.$store.state.appuntamento.edit_mode; },
            set( value ) { this.set_edit_mode( value ); }
        },
        task_qry_select () {
            return this.$store.state.task.open_modal;
        },
        open_modal () {
            return this.$store.state.form_modal.open_modal;
        },
        task_qry_save () {
            return this.$store.state.task.executed;
        },
        list_mode() {
            if ( !this.ricerca_id || ( this.ricerca_id && this.edit_mode ) ) { return 'default'; }
            if ( this.ricerca_id && !this.edit_mode ) { return 'personal'; }
        },
        fields_cards () {        // default: undefined
            if ( this.options_cards.hasOwnProperty('fields') ) {
                return this.options_cards.fields.map( column_field => {
                    if ( column_field.hasOwnProperty('class') === false ) {
                        column_field['class'] = [];
                    } else if ( typeof column_field['class'] === 'string' ) {
                        column_field['class'] = [ column_field['class'] ];
                    }
                    if ( column_field.hasOwnProperty( 'screen' ) ) {
                        var classes = [];
                        var screen_classes = this._get_column_class( column_field.screen );
                        for ( var i = 0; i < screen_classes.length; i++ ) {
                            var class_string = typeof column_field['class'][ i ] === 'string' ? column_field['class'][ i ] : '';
                            classes.push( class_string + ' ' + screen_classes[ i ]  );
                        }
                        column_field['class'] = classes;
                        delete column_field.screen;
                    }
                    return column_field;
                });
            }
            return undefined;
        },
        details_cmp_fields() {
            if ( this.options_cards.hasOwnProperty('detailscmp') ) { return this.options_cards.detailscmp; }
            return null;
        },
        email_schema() { return schemas.manual_email_schema; },
        total() {
            if ( this.show_map === true ) {
                if ( this.total_map !== null && this.total_map !== undefined ) {
                    return this.total_map;
                }
            } else {
                if ( this.items ) {
                    return this.tot;
                }
            }
            return 0;
        }
    },
    watch: {
        //total_map( new_val ) { console.log( 'total_map', new_val ); },
        task_qry_save(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`task/executed`);
                this.toggle_modal('modal-form');
                if ( this.$store.state.task.obj.hasOwnProperty('pratica_metas') && this.$store.state.task.obj.pratica_metas.hasOwnProperty('reload') ) {
                    this.force_reload();
                    this.reload_table();
                    if ( this.$store.state.task.obj.pratica_metas.reload === true ) {
                        //this.$refs.listbnv.page = 1;
                    }
                }
                setTimeout( () => {
                    this.make_toast( 'SALVATAGGIO TASK', 'task salvato con successo' );
                }, 300);
            }
        },
        ricerca_newlist_error( new_val ) {
            if ( new_val ) {
                this.new_list.invalid = true;
                this.new_list.error = new_val;
                setTimeout( () => {
                    this.new_list.invalid = null;
                    this.new_list.error = '';
                }, 2000 );
                return;
            }
        },
        ricerca_created( new_val ) {
            if ( new_val ) {
                this.new_list.disabled = false;
                this.toggle_modal('modal-newlist');
                this.$store.dispatch('ricerca/force_reload');
                this.key_user_list += 1;
                this.ricerca_id = this.ricerca_obj.id;
                this.edit_mode = false;
                this.key_lista += 1;
            }
        },
        open_modal(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.form = JSON.parse( JSON.stringify( this.$store.state.form_modal.data ) );
                this.$store.dispatch('form_modal/clear');
                this.$store.dispatch('task/get_by_id', { id: this.form.task_id });
            }
        },
        task_qry_select(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`task/close_modal`);
                var task = JSON.parse( JSON.stringify( this.$store.state.task.obj ) );
                var schema = meta_converter.get_schema( task.metas );
                this.form.task_id       = task.id;
                this.form.schema        = schema.schema;
                this.form.schema_config = {};
                this.form.record        = schema.record;
                if ( !this.form.description ) { this.form.description = task.task_label; }
                this.form.schema_name   = 'task';
                this.modal_filter_key += 1;
                this.toggle_modal('modal-form');
            }
        },
        loading( new_value ) {
            if ( new_value === true || ( new_value === false && this.show_map === false ) ) { return; }
            
            // Crea la lista che verra' filtrata poi per has_appuntamento
            this.panel = new task_panel( this.items );
            
            this.total_map = this.panel.tasks.length + 0;
            
            setTimeout( () => {
                //console.log( 'tot:', this.panel.tasks.length );
                this.map = new google.maps.Map( this.$refs.map, {
                    zoom    : 8,
                    center : { lat: 45.4408474, lng: 12.3155151 },
                });
                google.maps.event.addListenerOnce( this.map, 'bounds_changed', function() {
                    this.setZoom( Math.min( 11, this.getZoom() ) );
                });
                var bounds = new google.maps.LatLngBounds();
                
                // Se non c'e' nulla cerco di centrare la mappa
                if (this.panel.tasks == undefined || this.panel.tasks.length == 0) {
                    bounds.extend( { lat: 45.4408474, lng: 12.3155151 } );
                }

                this.panel.tasks.map( x => {
                    bounds.extend( x.latlng );
                    // Cambio icona a seconda che sia gia' assegnata ad un itinerario
                    let icon = !x.data.assegnata_itinerario ? `${ config.backend_url }icons/maps/5.png` : `${ config.backend_url }icons/maps/5-alt.png`; 

                    new google.maps.Marker({
                        position : x.latlng,
                        map      : this.map,
                        label    : x.codice,
                        icon     : icon,
                    });
                });
                this.map.setCenter( bounds.getCenter() );
                this.map.fitBounds( bounds );
                this.map.setZoom( this.map.getZoom() -1 );
                this.loading_map = false;
                this.key_total += 1;
            }, 1000 );
        },
//        show_map( new_value ) {
//            if ( new_value === false ) {
//                //this.remove_map();
//            }
//        },
    },
    methods : {
        rowClass(item, type) {
            if (type === 'row') {
                if (item.assegnata_itinerario) return 'assegnata-itinerario'
            }
            return undefined;
        },
        remove_map() {
            let element = this.$refs.map;
            if ( element ) {
                element.parentNode.removeChild(element);
            }
        },
        ...mapActions( 'appuntamento', {
            set_ricerca_id     : 'set_ricerca_id',
            set_edit_mode      : 'set_edit_mode',
        }),
        ...mapActions( 'ricerca', {
            destroy_ricerca : 'destroy_ricerca',
            create_ricerca  : 'create',
        }),
        ...mapActions( 'comune_provincia', {
            provincia_set_filter    : 'add_filter',
            provincia_remove_filter : 'remove_filter',
            provincia_force_reload  : 'force_reload',
        }),
        ...mapActions( 'comune_select', {
            comune_set_filter    : 'add_filter',
            comune_remove_filter : 'remove_filter',
            comune_force_reload  : 'force_reload',
        }),
        ...mapActions( 'appuntamento_list', {
            change_list_order  : 'change_list_order',
            report_set_clear   : 'set_clear_report',
            reset_report_store : 'reset_report',
            make_report        : 'make_report',
        }),
        set_filter( payload ) {
            //if ( this.list_mode === 'default' ) {
                this.$store.dispatch( 'appuntamento/add_filter', payload );
            //} else if ( this.list_mode === 'personal' ) {
                this.$store.dispatch( 'appuntamento_list/add_filter', payload );
            //}
        },
        remove_filter( key ) {
            if ( this.list_mode === 'default' ) {
                this.$store.dispatch( 'appuntamento/remove_filter', key );
            } else if ( this.list_mode === 'personal' ) {
                this.$store.dispatch( 'appuntamento_list/remove_filter', key );
            }
        },
        force_reload() {
            if ( this.list_mode === 'default' ) {
                this.$store.dispatch( 'appuntamento/force_reload' );
            } else if ( this.list_mode === 'personal' ) {
                this.$store.dispatch( 'appuntamento_list/force_reload' );
            }
        },
        save_filter_value( payload ) {
            //if ( this.list_mode === 'default' ) {
                this.$store.dispatch( 'appuntamento/save_filter_value', payload );
            //} else if ( this.list_mode === 'personal' ) {
                this.$store.dispatch( 'appuntamento_list/save_filter_value', payload );
            //}
        },
        remove_filter_value( key ) {
            //if ( this.list_mode === 'default' ) {
                this.$store.dispatch( 'appuntamento/remove_filter_value', key );
            //} else if ( this.list_mode === 'personal' ) {
                this.$store.dispatch( 'appuntamento_list/remove_filter_value', key );
            //}
        },
        save_modal_form_data: function(payload) {
            var data = { payload, id: this.form.task_id };
            this.$store.dispatch('task/updatemeta', data);
        },
        submit_modal_form() {
            this.$refs.modal_form.submit_form();
        },
        reset_modal_form() {
            this.$refs.modal_form.reseta();
        },
        toggle_modal(modal_id = 'modal-form') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-form' : modal_id;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        reload_table() {
            if ( this.list_mode === 'default' ) {
                this.key_pratiche += 1;
            } else if ( this.list_mode === 'personal' ) { // lista in visione
                this.key_lista += 1;
            }
        },
        // sezione itinerari
        user_list_change( payload ) {
            if ( payload.hasOwnProperty('ricerca_id') ) {
                this.ricerca_id = payload.ricerca_id;
                this.$store.dispatch('appuntamento_list/force_reload');
                this.$store.dispatch('appuntamento/force_reload');
            } else if ( payload.hasOwnProperty('edit_mode') ) {
                this.edit_mode = payload.edit_mode;
                if ( payload.edit_mode === true ) {
                    this.$store.dispatch('appuntamento/add_filter', { edit_mode: true });
                } else if ( payload.edit_mode === false ) {
                    this.$store.dispatch('appuntamento/remove_filter', 'edit_mode' );
                }
            } else if ( payload.hasOwnProperty('destroy_ricerca') ) {
                var confirm_options = {
                    buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                    cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
                };
                this.$bvModal.msgBoxConfirm('Eliminare la ricerca?', confirm_options)
                    .then(value => {
                        if ( !value ) { return; }
                        this.destroy_ricerca( payload.destroy_ricerca );
                        this.ricerca_id    = '';
                        this.edit_mode     = false;
                        setTimeout( () => { this.key_user_list += 1; }, 350 );
                    })
                    .catch(err => {
                        console.log( err );
                    });
            } else if ( payload.hasOwnProperty('new_ricerca') ) {
                this.toggle_modal('modal-newlist');
            }
            if ( this.show_map === false ) {
                this.reload_table();
                this.filters.key += 1;
            } else {
                if ( this.list_mode === 'default' ) {
                    this.$store.dispatch('appuntamento/get_all', { options: { paginate: false, include: false } });
                } else if ( this.list_mode === 'personal' ) {
                    this.$store.dispatch('appuntamento_list/get_all', { options: { paginate: false, include: false } });
                }
                this.map = null;
                this.panel = null;
                this.key_map += 1;
                this.loading_map = true;
            }
        },
        reset_modal_newlist() {
            this.new_list.text     = '';
            this.new_list.invalid  = null;
            this.new_list.disabled = false;
            this.error             = '';
        },
        create_new_ricerca() {
            if ( this.new_list.text.length < 4 ) {
                this.new_list.invalid = true;
                this.new_list.error = 'Lunghezza minima: 4 caratteri';
                setTimeout( () => {
                    this.new_list.invalid = null;
                    this.new_list.error = '';
                }, 2000 );
                return;
            }
            this.create_ricerca( { payload: { label: this.new_list.text } } );
        },
        new_list_focus() {
            this.$refs.new_list_input.focus();
        },
        update_filters( payload ) {
            if ( payload.hasOwnProperty('show_mine') ) {
                this.set_filter( { show_mine: payload.show_mine } );
            } else if ( payload.hasOwnProperty('show_map') ) {
                this.set_filter( { show_map: payload.show_map } );
//                this.set_filter( { no_luogo_appuntamento: false } );
//                this.set_filter( { primo_contatto_incompleto: false } );
//                this.set_filter({mostra_assegnati: false});
//                this.set_filter( { appuntamento_done: false } );
//                this.set_filter( { ispezione_done: false } );
                if ( payload.show_map === true ) {
                    this.loading_map = true;
                    // salvare il valore di page per poi ripristinarlo
                    //this.$store.dispatch('appuntamento/get_all', { options: { paginate: false, include: false } })
                } else {
                    this.key_main += 1;
                }
            } else if ( payload.hasOwnProperty('no_luogo_appuntamento') ) {
                this.set_filter( { no_luogo_appuntamento: payload.no_luogo_appuntamento } );
            } else if ( payload.hasOwnProperty('primo_contatto_incompleto') ) {
                this.set_filter( { primo_contatto_incompleto: payload.primo_contatto_incompleto } );
            } else if ( payload.hasOwnProperty('appuntamento_done') ) {
                this.set_filter( { appuntamento_done: payload.appuntamento_done } );
            } else if ( payload.hasOwnProperty('ispezione_done') ) {
                this.set_filter( { ispezione_done: payload.ispezione_done } );
            } else if ( payload.hasOwnProperty('mostra_assegnati') ) {
                this.set_filter( { mostra_assegnati: payload.mostra_assegnati } );
            } else if ( payload.hasOwnProperty('regione') ) { 
                let remove_provincia; let remove_comune;
                if ( payload.regione.id ) {
                    remove_provincia = !this.prov_check_regione( { regione: payload.regione.label, id: this.pratiche_filters.provincia } );
                    remove_comune = !this.comune_check_regione( { regione: payload.regione.label, id: this.pratiche_filters.comune } );
                    this.provincia_set_filter( { regione_id: payload.regione.id } );
                    this.comune_set_filter( { regione: payload.regione.label } );
                    this.set_filter( { regione: payload.regione.label } );
                    this.save_filter_value( { regione_id: payload.regione.id } );
                } else {
                    remove_provincia = !this.prov_check_regione( { regione: this.pratiche_filters.regione, id: this.pratiche_filters.provincia } );
                    remove_comune = !this.comune_check_regione( { regione: this.pratiche_filters.regione, id: this.pratiche_filters.comune } );
                    this.provincia_remove_filter('regione_id');
                    this.comune_remove_filter('regione');
                    this.remove_filter( 'regione' );
                    this.remove_filter_value( 'regione_id' );
                }
                if ( remove_provincia ) { this.remove_filter('provincia'); this.remove_filter_value('provincia_id'); };
                if ( remove_comune ) { this.remove_filter('comune'); this.remove_filter_value('comune_id') };
            } else if ( payload.hasOwnProperty('provincia') ) {
                let remove_comune;
                if ( payload.provincia.id ) {
                    remove_comune = !this.comune_check_provincia( { provincia: payload.provincia.label, id: this.pratiche_filters.comune } );
                    this.comune_set_filter( { provincia: payload.provincia.label } );
                    this.set_filter( { provincia: payload.provincia.label } );
                    this.save_filter_value( { provincia_id: payload.provincia.id } );
                } else {
                    remove_comune = !this.comune_check_provincia( { provincia: this.pratiche_filters.provincia, id: this.pratiche_filters.comune } );
                    if ( !this.pratiche_filters.regione ) { remove_comune = true; }
                    else { remove_comune = !this.comune_check_regione( { regione: this.pratiche_filters.regione, id: this.pratiche_filters.comune } ); }
                    this.comune_remove_filter('provincia');
                    this.remove_filter( 'provincia' );
                    this.remove_filter_value( 'provincia_id' );
                }
                if ( remove_comune ) { this.remove_filter('comune'); this.remove_filter_value('comune_id') };
            } else if ( payload.hasOwnProperty('comune') ) {
                if ( payload.comune.id ) {
                    this.set_filter( { comune: payload.comune.id } );
                    this.save_filter_value( { comune_id: payload.comune.id } );
                } else {
                    this.remove_filter( 'comune' );
                    this.remove_filter_value( 'comune_id' );
                }
            }
            this.force_reload();
            if ( this.show_map === false ) {
                this.reload_table();
                this.total_map = null;
            } else {
                if ( this.list_mode === 'default' ) {
                    this.$store.dispatch('appuntamento/get_all', { options: { paginate: false, include: false } });
                } else if ( this.list_mode === 'personal' ) {
                    this.$store.dispatch('appuntamento_list/get_all', { options: { paginate: false, include: false } });
                }
                this.map = null;
                this.panel = null;
                this.key_map += 1;
                this.loading_map = true;
            }
        },
        reorder( ev ) {
            let payload = { codice_pratica: ev.moved.element.codice, ricerca_id: this.ricerca_id, new_order: ev.moved.newIndex, old_order: ev.moved.oldIndex  };
            this.change_list_order( payload );
        },
        start_make_report() {
            setTimeout( () => {
                this.make_report( { ricerca_id: this.ricerca_id } );
            }, 1000);
        },
        reset_report() {
            this.report_set_clear()
            this.reset_report_store();
        },
        export_csv() {
            this.reset_report_store();
            this.$bvToast.show('report-toast');
        },
        right_click( obj ) {
            this.$refs.menu.open( obj.event, { data: obj.row } );
        },
        context_action( action, codice_pratica, read, pratica ) {
            if ( action === 'diary' ) {
                this.menu.component_name = 'diary';
                this.menu.component      = diary;
                this.menu.description    = `Diario della pratica ${ codice_pratica }`;
                this.menu.codice_pratica = codice_pratica;
                this.menu.show           = true;
                this.toggle_modal('modal-menu');
            } else if ( action === 'email' ) {
                var customer_id = read.owner;
                this.$store.dispatch('sollecito_document_bnvtag/set_customer_id', customer_id);
                this.$store.dispatch('sollecito_document_bnvtag/force_reload');
                this.$store.dispatch('pratica_addresses/load_pratica', read);
                this.email_record.codice_pratica = codice_pratica;
                this.email_record.customer_id = customer_id;
                this.toggle_modal('modal-email');
                this.pra_email = read;
            } else {
                if ( action === 'files' ) {
                    this.menu.component_name = 'files';
                    this.menu.component      = files;
                    this.menu.description    = `Pratica ${ codice_pratica } - Files`;
                } else if ( action = 'assegnazioni' ) {
                    this.menu.component_name = 'assegnazioni';
                    this.menu.component      = assegnazioni;
                    this.menu.description    = `Pratica ${ codice_pratica } - Assegnazioni`;
                }
                this.menu.codice_pratica = codice_pratica;
                this.menu.show           = true;
                this.toggle_modal('modal-menu');
            }
        },
        task_modified( task ) {    // chiamato dell'evento 'editedtask' del component 'assengnazioni' e del component 'custodian'
            if ( typeof task === 'string' && task === 'custodian - reload' ) {
                this.$refs.listbnv.page = 1;
                this.toggle_modal('modal-menu');
            } else if (                              // ricarica la lista se lo stato del task appena modificato
                task.newstate === this.state_tab  // è uguale allo stato in visione e show_mine = true
                //this.show_mine === true
                ) {
                this.$store.dispatch('pratica/force_reload');
                this.$refs.listbnv.page = 1;
            }
        },
        change_menu_save_disabled( value ) {
            //this.menu.save_disabled = !value;
        },
        reset_modal_email() {},
        save_modal_email( data ) {
             var pra = this.pra_email;
             var error_email = false;
             var man = manager.validate( pra, data.body_email1 );
             if ( man.is_valid === false ) {
                 this.$refs.form_email.set_error({ body_email1: man.error })
             } else {
                 var mailto = data.to.map( x => x.label ).join(';') + '?';
                 if ( data.cc.length > 0 ) {
                     mailto += 'cc=' + data.cc.map( x => x.label ).join(';')
                 }
                 if ( data.bcc.length > 0 ) {
                     if ( mailto.endsWith('?') === false ) { mailto += '&'; }
                     mailto += 'bcc=' + data.bcc.map( x => x.label ).join(';')
                 }
                 if ( mailto.endsWith('?') === false ) { mailto += '&'; }
                 mailto += 'subject=' + encodeURI( man.subject );
                 var translation = man.translation;
                 if ( data.docs.length > 0 ) {
                     var docs_string = data.docs.map( x => { return `  - ${ x.label }` } ).join('\n');
                     translation = translation.replace('{{documenti}}', docs_string);
                 }
                 mailto += '&body=' + encodeURI( translation );
                 var link = document.createElement("a");
                 link.id   = 'email_link';
                 link.href = "mailto:" + mailto;
                 link.setAttribute('target', '_blank');
                 document.body.appendChild( link )
                 document.getElementById('email_link').click();
                 link.remove();
                 this.toggle_modal('modal-email');
                 this.pra_email = null;
                 this.email_record = {};
             }
        },
        submit_email_form() {
            this.$refs.form_email.submit_form();
        },
        email_payload_changed( payload ) {
            var data = {};
            if ( payload.hasOwnProperty('template1_id') ) {
                var template_id  = payload['template1_id'];
                var target_field = 'body_email1';
                var target_label = 'Corpo prima email';
            } else if ( payload.hasOwnProperty('template2_id') ) {
                var template_id  = payload['template2_id'];
                var target_field = 'body_email2';
                var target_label = 'Corpo solleciti';
            } else {
                return;
            }
            var body_template = this.$store.getters['email_template/email_body']( template_id );
            data[ target_field ] = body_template;
            var body_written = this.$refs.form_email.get_value_by_element_name( target_field );
            if ( body_written ) {
                var confirm_options = {
                    buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                    cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
                };
                this.$bvModal.msgBoxConfirm(`Il campo "${ target_label }" non è vuoto, sovrascrivere il contenuto?`, confirm_options)
                    .then(value => {
                        if ( value === true ) {
                            this.$refs.form_email.set_payload( data, true ); // true è il force
                        }
                    })
                    .catch(err => {
                        console.log( err );
                    });
            } else {
                this.$refs.form_email.set_payload( data );
            }
        },
        set_filters( payload ) {
            if ( payload.text ) {
                this.$store.dispatch('appuntamento/add_filter', { contains: payload.text });
            } else {
                this.$store.dispatch('appuntamento/remove_filter', 'contains');
            }
            if ( payload.date_filters && payload.date_filters.length > 0 ) {
                this.$store.dispatch('appuntamento/add_filter', { date_filters: payload.date_filters });
            } else {
                this.$store.dispatch('appuntamento/remove_filter', 'date_filters');
            }
            this.$store.dispatch('appuntamento/force_reload');
            if ( this.maps === false ) {
                this.$refs.listbnv.page = 1;
            } else {
                // ricaricare la mappa
                this.loading_map = true;
                this.$store.dispatch('appuntamento/get_all', { options: { paginate: false, include: false } })
                this.map = null;
                this.panel = null;
                this.key_map += 1;
            }
        },
    },
    created() {
        this.options_cards = options[ 'appuntamento_list' ];
    },
    data() {
        return {
            modal_filter_key : 0,
            form              : {
                schema_name   : null,
                schema        : null,
                schema_config : {},
                record        : {},
                task_id       : null,
                description   : null,
            },
            menu             : {
                title          : null,
                show           : false,
                key            : 0,
                component      : null,
                codice_pratica : null,
                component_name : null,
                description    : null,
                save_disabled  : false,
            },
            email_record     : {},
            key_user_list    : -1000,
            key_pratiche     : 0,
            key_main         : 5000,
            key_lista        : 1000,
            key_map          : 10000,
            key_total        : 100000,
            modal_form_email : -500,
            loading_map      : false,
            new_list: {
                text     : '',
                invalid  : null,
                error    : '',
                disabled : false,
            },
            filters: {
                key : 1000000,
            },
            total_map: null,
        }
    },
}

</script>

<template>
    <div style="width: 100%; margin: auto">

        <b-row>

            <b-col cols="2" style="padding: 10px;">
                <appuntamento-filters
                    :key                   = "filters.key"
                    :show_mine             = "pratiche_filters.show_mine"
                    :show_map              = "pratiche_filters.show_map"
                    :no_luogo_appuntamento = "pratiche_filters.no_luogo_appuntamento"
                    :primo_contatto_incompleto = "pratiche_filters.primo_contatto_incompleto"
                    :appuntamento_done  = "pratiche_filters.appuntamento_done"
                    :ispezione_done = "pratiche_filters.ispezione_done"
                    :mostra_assegnati = "pratiche_filters.mostra_assegnati"
                    :regione_id            = "regione_id"
                    :provincia_id          = "provincia_id"
                    :comune_id             = "comune_id"
                    @change                = "update_filters"
                    @set_filters           = "set_filters"
                />
                <user-list
                    :key        = "key_user_list"
                    :ricerca_id = "ricerca_id"
                    :edit_mode  = "edit_mode"
                    :show_map   = "pratiche_filters.show_map"
                    @change     = "user_list_change"
                    @export     = "export_csv"
                />
            </b-col>

            <b-col cols="10" :key="key_main">
                <b-row>
                    <b-col cols="2">
                    </b-col>
                    <b-col cols="8">
                        <h3 style="text-align: center; padding-bottom: 14px;">{{ title.toUpperCase() }}</h3>
                    </b-col>
                    <b-col cols="2">
                        <b-badge style="font-size: 12pt" :key="key_total">Totale: {{ total }}</b-badge>
                    </b-col>
                </b-row>
 
                <!-- VISIONE MAPPA -->
                <div v-if="show_map === true">
                    <div v-if="loading_map === true" class="text-center" style="padding-top: 20px;">
                        <b-spinner type="grow" label="Loading..."></b-spinner>
                    </div>
                    <div v-show="loading_map === false" id="map" style="width: 100%; height: 700px;" ref="map" :key="key_map"></div>
                <!-- VISIONE STANDARD -->
                </div>
                <div class="pic-small-table" v-else-if="show_map === false && list_mode === 'default'" style="width: 95%; margin: auto">
                    <bnvlist
                        :key             = "key_pratiche"
                        :schema          = "schema_name"
                        storename        = "appuntamento"
                        :skip            = "skip"
                        :fields          = "fields"
                        :primarykey      = "primary_key"
                        :qryoptions      = "qry_options"
                        :details         = "details"
                        :detailsclass    = "details_class"
                        :detailsaction   = "details_action"
                        :edit            = "edit"
                        :editaction      = "edit_action"
                        :paginate        = "paginate"
                        :link            = "link"
                        :rowclass        = "rowClass"
                        :linkfunction    = "linkfunction"
                        :initialfilter   = "initialfilter"
                        ref              = "listbnv"
                        :size            = "size"
                        :formpath        = "item_form"
                        :destroy         = "destroy"
                        :display_total   = "true"
                        @row_selected    = "row_selected"
                        @right-click     = "right_click"
                    />
                </div>
                <!-- VISIONE ITINERARI -->
                <div class="pic-small" v-else="show_map === false && list_mode === 'personal'" style="width: 80%; margin: auto">
                    <bnvcards
                        :draggable       = "is_draggable"
                        :key             = "key_lista"
                        schema           = "appuntamento_list"
                        storename        = "appuntamento_list"
                        :skip            = "skip"
                        :fields          = "fields_cards"
                        :primarykey      = "primary_key"
                        :qryoptions      = "qry_options"
                        :details         = "true"
                        :detailsclass    = "details_class"
                        :detailscmp      = "details_cmp_fields"
                        :detailsaction   = "details_action"
                        :edit            = "edit"
                        :editaction      = "edit_action"
                        :paginate        = "paginate"
                        :link            = "link"
                        :rowclass        = "rowClass"
                        :linkfunction    = "linkfunction"
                        :initialfilter   = "initialfilter"
                        ref              = "listbnv"
                        :size            = "size"
                        :formpath        = "item_form"
                        :display_total   = "true"
                        @row-selected    = "row_selected"
                        @right-click     = "right_click"
                        @moved           = "reorder"
                    />
                </div>
            </b-col>

        </b-row>

        <!-- menu contestuale -->
        <vue-context ref="menu">
            <template slot-scope="child">
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'files', child.data.data.codice )">Files</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'diary', child.data.data.codice )">Diario</a>
                </li>
                <li style="cursor: pointer">
                    <a @click.prevent="context_action( 'email', child.data.data.codice, child.data.data )">Nuova Email</a>
                </li>
            </template> 
        </vue-context>

        <!-- modal form email -->
        <b-modal
            id          = "modal-email"
            title       = "Nuova Email"
            @hide       = "reset_modal_email"
            size        = "lg"
            button-size = "sm"
            >
            <bnvform
                :key       = "modal_form_email"
                name       = "manual_email"
                :schema    = "email_schema"
                :options   = "{}"
                :record    = "email_record"
                :submit    = "false"
                :inline    = "true"
                @change    = "email_payload_changed"
                ref        = "form_email"
                @submitted = "save_modal_email"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_email_form">
                      Invia
                    </b-button>
                <!-- </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button> -->
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal per le form dei task -->
        <b-modal
            id          = "modal-form"
            :title      = "form.description ? form.description.charAt(0).toUpperCase() + form.description.slice(1) : ''"
            @hide       = "reset_modal_form"
            button-size = "sm"
            >
            <!-- ho dovuto togliere la riga:
                 v-if       = "form.schema_name"
                 ma non ho capito il perché (2022.10.31)
            -->
            <bnvform
                :key       = "modal_filter_key"
                :name      = "form.schema_name"
                :schema    = "form.schema"
                :options   = "form.schema_config"
                :record    = "form.record"
                :submit    = "false"
                :inline    = "true"
                ref        = "modal_form"
                layout     = "single"
                @submitted = "save_modal_form_data"
            ></bnvform>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_modal_form">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal new list -->
        <b-modal
            id          = "modal-newlist"
            title       = "Nuova Ricerca"
            @hide       = "reset_modal_newlist"
            @shown      = "new_list_focus"
            button-size = "sm"
            >
            <label for="new_list">Etichetta</label>
            <b-form-input
                id           = "new_list"
                ref          = "new_list_input"
                v-bind:class = "{ 'is-invalid': new_list.invalid }"
                v-model      = "new_list.text"
                :disabled    = "new_list.disabled"
                placeholder  = "nome della ricerca..."
                size         ="sm"
            />
            <div v-if="new_list.error" class="invalid-feedback">
                {{ new_list.error }}
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="create_new_ricerca">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal per le voci del menu contestuale -->
        <b-modal
            id          = "modal-menu"
            :title      = "menu.description"
            button-size = "sm"
            size        = "lg"
            >
            <component
                v-if              = "menu.show"
                :ref              = "menu.key"
                :is               = "menu.component"
                :codice_pratica   = "menu.codice_pratica"
                :modal            = "true"
                schema_from_route = "diary"
                @editedtask       = "task_modified"
                @is-valid         = "change_menu_save_disabled"
            ></component>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col v-if="menu.component_name === 'custodian'">
                    <b-button size="sm" variant="success" @click="$refs[ menu.key ].save()" :disabled="menu.save_disabled">
                      Salva
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <b-toast
            no-auto-hide
            id       = "report-toast"
            :title   = "report_message.class"
            :variant = "report_variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            @shown   = "start_make_report"
            @hidden  = "reset_report"
            >
			<span v-if="report_message.hasOwnProperty('url') === false">{{ report_message.action }}</span><a v-if="report_message.hasOwnProperty('url')" :href="report_message.url" target="_blank">{{ report_message.file_name }}</a>
<b-spinner v-if="report_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

    </div>
</template>

<style>
.assegnata-itinerario {
    background-color: #0897B4!important;
}
</style>

