////////////////////////////////////////

const axios = require('axios');
const moment = require('moment');

import { pratica_service } from '@/services';
import base_module from '@/vue-model-mirror/stores/base.module';
import pratica_registries from '@/praticav2/classes/pratica_registries';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import { authHeader } from '@/helpers';
import { router } from '@/helpers';

var options = {
    model_name    : 'pratica',
    send_alert    : false,
    default_order : 'codice DESC',
};

var base = new base_module( options, pratica_service );

const state = {
    ...base.state,
    show_archived  : false,
    show_mine      : true,
    show_completed : false,
    //state_tab      : 'nuovo',
    state_tab      : null,
    tab_counter    : {},
    tasks          : [],
    test_comp      : null,
    simple_search  : {},
    switch_tab     : false,
    page           : 1,
    // newregistry
    registry_setted   : false,
    prareg            : null,
    cga_updated       : null,
    text_filter       : '',
    text_filter_valid : true,
    date_filters      : [ { field: '', range: null } ],
    // set template
    wscode_settemplate      : null,
    settemplate_in_progress : false,
};

const getters = {
    ...base.getters,

    pratica_parcel: state => {
        return state.obj === null ? [] : state.obj.my_parcels.sort( ( a, b ) => ( a.key > b.key ) ? 1 : -1 );
    },
    get_obj: state => ( state.obj === null ) ? null : state.obj,
    get_prareg: state => ( state.prareg === null ) ? null : state.prareg,
    get_item: state => ( codice_pratica ) => {
        if ( state.items === null && state.obj === null ) { return null; }
        if ( state.items !== null ) {
            var res = state.items.filter( x => x.codice === parseInt( codice_pratica ) );
            //console.log('restituisco da items');
            return res.length === 1 ? res[0] : null;
        }
        //console.log('restituisco da obj');
        return state.obj;
    },
//  contains: state => {
//      if ( state.filters.hasOwnProperty('contains') ) {
//          return state.filters.contains;
//      }
//      return '';
//  },
};

const actions = {
     ...base.actions,

    // newregistry
    set_preg( { commit, rootGetters }, payload ) {
        let preg = payload.preg;
        let role_label = payload.label || rootGetters['registry_role/map'].get( preg.role_id ).label;
        if ( payload.hasOwnProperty('preg_relation') ) {
            preg.father_id = payload.preg_relation.father_id;
            if ( role_label.split('_').length < 2 ) { // in alcuni casi mi arriva 'controparte_referente', in altri 'referente'
                role_label = rootGetters['registry_role/map'].get( payload.preg_relation.father_role_id ).label + '_' + role_label; }
            }
            commit( 'set_preg', { [ role_label ]: preg } );
        if ( payload.hasOwnProperty('preg_relation') ) {
            commit( 'set_preg_relation', payload.preg_relation );
        }
    },
    set_pregs( { commit, rootGetters }, payload ) {
        commit( 'set_pregs', payload );
    },
    set_incarico( { commit }, payload ) {
        commit( 'set_incarico', payload );
    },
    set_cga( { commit }, obj ) {
        commit( 'set_cga', obj );
    },
    //

    test_comp({commit}) { commit('test_comp', 'sono la stringa YES sovrascritta'); },

    update_pratica_parcel( { commit }, pratica_parcel ) { commit( 'update_pratica_parcel', pratica_parcel ); },

    override_pratica( { commit }, pratica ) { commit('override_pratica', pratica); },

    async get_all({commit, state, getters, dispatch}, _data = { options: {} }) {
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        data.options.show_archived  = state.show_archived;
        data.options.show_mine      = state.show_mine;
        data.options.show_completed = state.show_completed;
        data.options.newstate       = state.state_tab;
        data.options.order          = state.order;
        Object.keys( state.filters ).map( key => {
            if ( key === 'date_filters' ) {
                let date_filters = state.filters.date_filters;
                for ( var i = 0; i < state.filters.date_filters.length; i++ ) {
                    data.options[ date_filters[ i ].field ] = [
                        moment( date_filters[ i ].range[0] ).format('YYYY-MM-DD'),
                        moment( date_filters[ i ].range[1] ).format('YYYY-MM-DD'),
                    ].join('|');
                }
            } else {
                data.options[ key ] = state.filters[ key ];
            }
        });
        if ( state.filters.contains && state.filters.contains.match(/^\d+\.$/) ) {
            let match = state.filters.contains.match(/^(\d+)\.$/);
            let url = config.backend_url + 'model/pratica/exists?cp=' + match[1];
            let options = { headers: authHeader() };
            let exists = await axios.get( url, options )
                  .then( response => {
                      return true;
                  })
                  .catch( error => {
                      //console.log( error.response.status );
                      return false;
                  });
            state.text_filter_valid = exists;
            router.push( '/form/pratica/' + match[1] );
            commit('set_text_filter', '')
            return;
        } else {
            state.text_filter_valid = true;
        }
//      if ( state.switch_tab === false ) {
            dispatch( '_get_all', data );
//      } else {
//          commit('set_switch_tab', false);
//          dispatch( '_get_all_switch', data );
//      }
    },

    _get_all_switch({commit, state, getters, rootGetters}, data) {
        commit('start_request');
        state.service.get_count(getters.model_name, data.options)
            .then( response => {
                var state_label = rootGetters['statemanager/state_label']( state.state_tab );
                //console.log( response );
                commit('set_tab_counter', response);
                if ( response[ state_label ].tot === 0 ) {
                    // v1: se non trova pratiche le cerca sempre partendo da 'nuovo'
                    // v2: se non trova pratiche le cerca sempre partendo dalla scheda a destra
                    //var states = [ 'nuovo', 'lavorazione', 'evasione', 'chiuso', 'nuovo', 'lavorazione', 'evasione' ].filter( x => x !== state.state_tab ); // v1
                    var first_good_state = null;
                    var states = [ 'nuovo', 'lavorazione', 'evasione', 'chiuso', 'nuovo', 'lavorazione', 'evasione' ]; // v2
                    var found_my_state = false; // v2
                    for ( var i = 0; i < states.length; i++ ) {
                        if ( found_my_state === false ) { // v2
                            var found_my_state = states[ i ] === state.state_tab ? true : false; // v2
                            continue; // v2
                        } // v2
                        if ( response[ states[ i ] ].tot === 0 ) { continue; }
                        var first_good_state = states[ i ];
                        break;
                    }
                    if ( first_good_state ) {
                        commit( 'settab', first_good_state );
                        data.options.newstate = first_good_state;
                    }
                }
                state.service.get_all(getters.model_name, data.options)
                    .then( response => {
                        if ( data.options.paginate === true ) { // gestione paginator
                            commit('set_total', response.tot);
                            commit('set_page', response.page);
                            commit('set_rows_per_page', response.rows_per_page);
                            commit('success_list', response.data);
                        } else {
                            commit('success_list', response)
                        }
                    })
                    .catch( error => {
                        commit('error', error)
                    });
            })
            .catch( error => {
                console.log( error );
                commit('error', error)
            });
    },

    _get_all({commit, state, getters, rootGetters}, data) {
        commit('start_request');
        let state_tab = state.state_tab;
        let states = []
        rootGetters['statemanager/states']
        .map( x => {
           if ( x.key > state_tab ) { states.push( x ); }
           else { states.unshift( x ); }
        });
        state.service.get_count(getters.model_name, data.options)
            .then( response => {
                commit('set_tab_counter', response);
                console.log( state.state_tab );
                for ( var i = 0; i < states.length; i++ ) {
                    if ( response[ states[ i ].label ].tot > 0 ) {
                        state_tab = states[ i ].key;
                        break;
                    }
                }
                data.options.newstate = state_tab;
                state.state_tab = state_tab;
                state.service.get_all(getters.model_name, data.options)
                    .then( response => {
                        if ( data.options.paginate === true ) { // gestione paginator
                            commit('set_total', response.tot);
                            commit('set_page', response.page);
                            commit('set_rows_per_page', response.rows_per_page);
                            commit('success_list', response.data);
                        } else {
                            commit('success_list', response)
                        }
                    })
                    .catch( error => {
                        commit('error', error)
                    })
            })
            .catch( error => {
                commit('error', error)
            });
//      state.service.get_all(getters.model_name, data.options)
//          .then( response => {
//              if ( data.options.paginate === true ) { // gestione paginator
//                  commit('set_total', response.tot);
//                  commit('set_page', response.page);
//                  commit('set_rows_per_page', response.rows_per_page);
//                  commit('success_list', response.data);
//              } else {
//                  commit('success_list', response)
//              }
//              state.service.get_count(getters.model_name, data.options)
//                  .then( response => {
//                      commit('set_tab_counter', response);
//                  })
//                  .catch( error => {
//                      commit('error', error)
//                  });
//          });
//          .catch( error => {
//              commit('error', error)
//          });
    },

    create({ commit, rootGetters }, data) {
        commit('start_request');
        data.payload.created_by = JSON.parse( localStorage.getItem('user') ).id;     // TODO il backend prende il dato da jwt
        data.payload.newstate   = rootGetters['statemanager/first_state_id'];
        pratica_service.create( 'pratica', data.payload, data.include )
            .then( obj => {
                commit('successcreate', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },

    settemplate_in_progress( { commit }, value ) {
        commit( 'settemplate_in_progress', value )
    },

    set_template({ commit, dispatch }, data) {
        let url = config.backend_url + 'model/pratica/settemplate';
        let options = { headers: authHeader() };
        axios.post( url, data, options )
              .then( response => {
                  console.log( response.data );
                  commit('set_wscode_settemplate', response.data);
                  dispatch('settemplate_in_progress', true);
              })
              .catch( error => {
                  //console.log( error.response.status );
                  return false;
              });
    },

    update({ commit, state, getters, dispatch }, data) {
        commit('start_request');
        state.service.update( getters.model_name, data.codice, data.payload, data.include )
            .then( obj => {
                commit('success', obj);
            })
            .catch( error => {
                commit('error', error)
            });
    },

    export_csv({commit, state, getters}, codice_pratica) {
        state.service.export_csv(getters.model_name, codice_pratica, true)
            //.then( rows => {
            //    commit('success_obj', rows);
            //    commit('success_select');
            //})
            .catch( error => {
                commit('error', error)
            });
    },

    get_tasks({dispatch, commit, state, getters}, codice_pratica) {
        commit('reset_tasks');
        state.service.get_tasks(getters.model_name, codice_pratica)
            .then( res => {
                commit('set_tasks', res);
            })
            .catch( error => {
                commit('error', error)
            })
    },

    set_read({dispatch, commit, state, getters}, data) {
        var pra = [];
        var read_status = null;
        if ( state.items ) {
            pra = state.items.filter( x => x.codice === parseInt(data.codice_pratica) );
            read_status = pra.length === 0 ? false : pra[0].read;
        }
        if (
                read_status === null ||    // se non ci sono items (non posso verificare lo stato)
                data.read !== read_status  // lo stato deve cambiare
            ) {
            state.service.set_read(getters.model_name, data.codice_pratica, data.read)
                .then( res => {
                    if ( res.executed ) {
                        if ( res.read === false ) { commit( 'add_new', res.state_label ); }
                        else if ( res.read === true ) { commit( 'remove_new', res.state_label ); }
                    }
                    if ( read_status !== null ) { pra[ 0 ].read = res.read; }
                });
        }
    },

    get_link( { commit, state, getters }, data ) {
        state.service.get_link( getters.model_name, data.codice, data.file_id )
            .then( obj => {
                window.open(obj.link, '_blank');
            })
            .catch( error => {
                commit('error', error)
            });
    },


    set_attribute({ commit }, data) { commit('ovveride_attribute', data); },
    override_task( {commit}, task ) { commit('overridetask', task); },
    overridemetas({commit}, metas) { commit('overridemetas', metas); },
    overridetasklabel({commit}, data) { commit('overridetasklabel', data); },
    set_show_archived( {commit}, value ) { commit('setshowarchived', value); },
    set_show_mine( {commit}, value ) { commit('setshowmine', value); },
    set_show_completed( {commit}, value ) { commit('setshowcompleted', value); },
    set_tab( {commit}, index ) { commit('settab', index) },
    set_simple_search( { commit }, filter ) {
        commit('set_simple_search', filter);
    },
    set_switch_tab( { commit }, value ) { commit( 'set_switch_tab', value ); },
    set_text_filter( { commit }, value ) { commit( 'set_text_filter', value ); },
    set_date_filters( { commit }, value ) { commit( 'set_date_filters', value ); },

};

const mutations = {
    ...base.mutations,

    settemplate_in_progress( state, value ) {
        state.settemplate_in_progress = value;
    },

    set_wscode_settemplate( state, data ) {
        console.log( 'module', data );
        state.wscode_settemplate = data.wscode;
    },

    // newregistry
    set_text_filter( state, value ) { state.text_filter = value; },
    set_date_filters( state, value ) { state.date_filters = value; console.log( 'date_filters', state.date_filters ); },
    set_pregs( state, payload ) {
        let keys = Object.keys( payload );
        let pra = JSON.parse( JSON.stringify( state.obj ) )
        let must_be_array = [ 'controparte', 'controparte_referente', 'riparatore', 'riparatore_referente', 'autorita', 'autorita_referente', 'testimone', 'testimone_referente', ];
        let main_key; let id;
        keys.map( key => {
            if ( must_be_array.includes( key ) ) {
                if ( key.includes( 'controparte' ) ) {
                    main_key = 'controparte';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'riparatore' ) ) {
                    main_key = 'riparatore';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'testimone' ) ) {
                    main_key = 'testimone';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'autorita' ) ) {
                    main_key = 'autorita';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                }
                if ( !pra.pregs.hasOwnProperty( main_key ) ) { pra.pregs[ main_key ] = {}; }
                if ( !pra.pregs[ main_key ].hasOwnProperty( String( id ) ) ) { pra.pregs[ main_key ][ String( id ) ] = {}; }
                pra.pregs[ main_key ][ String( id ) ][ key ] = payload[ key ];
                if ( key.includes( 'referente' ) ) {
                    state.prareg[ key ] = { [ key ]: payload[ key ], father_id: id };;
                } else {
                    state.prareg[ key ] = payload[ key ];
                }
                //
            } else {
                pra.pregs[ key ] = payload[ key ];
                state.prareg[ key ] = payload[ key ];
            }
        });
        state.obj = pra;
    },
    set_preg( state, payload ) {
        if ( state.obj && state.obj.hasOwnProperty('pregs') ) {
            let [ key ] = Object.keys( payload );
            console.log( 'key', key );
            let pra = JSON.parse( JSON.stringify( state.obj ) )
            let must_be_array = [ 'controparte', 'controparte_referente', 'riparatore', 'riparatore_referente', 'autorita', 'autorita_referente', 'testimone', 'testimone_referente' ];
            let main_key; let id;
            if ( must_be_array.includes( key ) ) {
                if ( key.includes( 'controparte' ) ) {
                    main_key = 'controparte';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'riparatore' ) ) {
                    main_key = 'riparatore';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'testimone' ) ) {
                    main_key = 'testimone';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                } else if ( key.includes( 'autorita' ) ) {
                    main_key = 'autorita';
                    id = payload[ key ].hasOwnProperty( 'father_id' ) ? payload[ key ].father_id : payload[ key ].id;
                }
                if ( !pra.pregs.hasOwnProperty( main_key ) ) { pra.pregs[ main_key ] = {}; }
                if ( !pra.pregs[ main_key ].hasOwnProperty( String( id ) ) ) { pra.pregs[ main_key ][ String( id ) ] = {}; }
                pra.pregs[ main_key ][ String( id ) ][ key ] = payload[ key ];
            } else {
                pra.pregs[ key ] = payload[ key ];
            }
            state.obj    = pra;
            state.prareg = new pratica_registries( pra );
            state.registry_setted = true;
            setTimeout( () => {
                state.registry_setted = false;
            }, 200 );
        } else {
            console.log('ALERT: pratica.mutations.set_preg non ha potuto settare la registry della pratica');
        }
    },
    set_incarico( state, payload ) {
        state.obj.pregs.incarico = payload;
        state.prareg.incarico = payload;
    },
    set_preg_relation( state, payload ) {
        if ( state.obj && state.obj.hasOwnProperty('preg_relations') ) {
            let pra = JSON.parse( JSON.stringify( state.obj ) )
            pra.preg_relations.push( payload );
            state.obj = pra;
            state.prareg   = new pratica_registries( pra );
            state.registry_setted = true;
            setTimeout( () => {
                state.registry_setted = false;
            }, 200 );
        } else {
            console.log('ALERT: pratica.mutations.set_preg non ha potuto settare la registry della pratica');
        }
    },
    success_obj( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = false;
        state.executed_obj = true;
        state.prareg   = new pratica_registries( obj );
        setTimeout( () => { state.executed_obj = false; }, 200)
    },
    set_cga( state, obj ) {
        console.log( obj );
        state.obj.pregs.assicurato_polizza.cgas = obj.cgas;
        state.prareg   = new pratica_registries( state.obj );
        state.cga_updated = true;
        setTimeout( () => {
            state.cga_updated = null;
        }, 200);
    },
    //

    test_comp( state, value ) { state.test_comp = value; },

    override_pratica( state, pra ) {
        if ( state.obj && state.obj.codice === pra.codice ) { state.obj = pra };
        if ( state.items ) {
            var index = null;
            for ( var i = 0; i < state.items.length; i++ ) { 
                if ( state.items[ i ].codice === pra.codice ) { index = i; }
            }
            if ( index ) { state.items[ index ] = pra; }
        }
    },

    update_pratica_parcel( state, pratica_parcel ) {
        for ( var i = 0; i < state.obj.my_parcels.length; i++  ) {
            if ( state.obj.my_parcels[ i ].id === pratica_parcel.id ) { state.obj.my_parcels[ i ] = pratica_parcel; break; }
        }
    },

    set_tasks( state, rows ) {
        for ( var i = 0; i < rows.length; i++ ) { // TODO sistemare "Task label"
            var task = rows[ i ];
            task['status'] = task.label;
            task.label     = task.task_label;
            task.id        = task.task_label;
            state.tasks.push( task );
        }
    },

    reset_tasks( state ) { state.tasks = []; },

    remove_new( state, state_label ) {
        if ( state.tab_counter && state.tab_counter.hasOwnProperty( state_label ) && state.tab_counter[ state_label ].new > 0 ) {
            state.tab_counter[ state_label ].new -= 1;
        }
    },

    add_new( state, state_label ) {
        if ( state.tab_counter && state.tab_counter.hasOwnProperty( state_label ) && state.tab_counter[ state_label ].hasOwnProperty( 'new' ) ) {
            state.tab_counter[ state_label ].new += 1;
        }
    },

    settab( state, index ) { state.state_tab = index; },

    setshowarchived( state, value ) { state.show_archived = value; },

    setshowmine( state, value ) { state.show_mine = value; },

    setshowcompleted( state, value ) { state.show_completed = value; },

    overridemetas(state, metas) {
        if ( metas.metas.length > 0 ) {
            var pratica_index = null;
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice === metas.codice ) { pratica_index = i; }
            }
            metas.metas.map( x => {
                state.items[ pratica_index ].metas[ x.name ] = x.value;
            });
        }
    },

    overridetasklabel(state, data) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice === data.codice_pratica ) {
                    for ( var a = 0; a < state.items[ i ].tasks.length; a++) {
                        if ( state.items[ i ].tasks[ a ].id === data.id ) {
                            state.items[ i ].tasks[ a ].label = data.label;
                            break;
                        }
                    }
                    break;
                }
            }
        }
    },

    set_tab_counter( state, data ) { state.tab_counter = data; },

    ovveride_attribute(state, data) { state.obj[ data.selected ] = data.data; },

    overridetask(state, task) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice === task.codice_pratica ) {
                    for ( var x = 0; x < state.items[ i ].tasks.length; x++ ) {
                        if ( state.items[ i ].tasks[ x ].id === task.id ) {
                            //state.items[ i ].tasks[ x ] = null;
                            state.items[ i ].tasks[ x ] = task;
                            break;
                        }
                    }
                    break;
                }
            }
        }
        var found = false;
        for ( var i = 0; i < state.obj.tasks.length; i++ ) { if ( state.obj.tasks[ i ].id === task.id ) { found = true; break; } }
        if ( found ) { state.obj.tasks[ i ] = task; }
    },
    set_simple_search( state, filter ) { state.simple_search = filter; },
    set_switch_tab( state, value ) { state.switch_tab = value; },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.show_mine     = true;
        state.show_completed = false;
        state.state_tab      = null;
        state.tab_counter    = {};
        state.tasks          = [];
        state.test_comp      = null;
        state.simple_search  = {};
        state.switch_tab     = false;
    },

};

export default {
    namespaced : true,
    state      : state,
    getters    : getters,
    actions    : actions,
    mutations  : mutations,
};

